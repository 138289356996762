$(document).foundation();

function openDatenschutz() {
	var $modal = $('#datenschutz');

	$.ajax('./datenschutz.html')
	  .done(function(resp){
	    $modal.html(resp).foundation('open');
	});
}

function openImpressum() {
	var $modal = $('#impressum');

	$.ajax('./impressum.html')
	  .done(function(resp){
	    $modal.html(resp).foundation('open');
	});
}